var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.is_mounted
    ? _c("div", { attrs: { align: "center" } }, [
        _c("br"),
        _vm._m(0),
        _c("div", { staticClass: "row justify-center" }, [
          _c(
            "div",
            {
              staticClass: "col-12 col-md-12 q-pr-md",
              attrs: { align: "center" },
            },
            [
              _c("hr"),
              _c("QQButton", {
                attrs: {
                  label: "Modifica dati Cliente",
                  color: "blue-grey",
                  icon: "mdi-alarm",
                  size: "md",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onModificaCliente()
                  },
                },
              }),
              _vm.pagina_sorgente === "TabellaFattureNonEmesse"
                ? _c("QQButton", {
                    attrs: {
                      label: "Modifica dati Contratto",
                      color: "blue-grey",
                      icon: "mdi-alarm",
                      size: "md",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onModificaContratto()
                      },
                    },
                  })
                : _vm._e(),
              _c("QQButton", {
                attrs: {
                  label: "Registro movimenti",
                  color: "blue-grey",
                  icon: "mdi-clipboard-check-multiple-outline",
                  size: "md",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onRegistroMovimenti()
                  },
                },
              }),
              _c("hr"),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "col-12 col-md-5 q-pr-md",
              attrs: { align: "left" },
            },
            [
              _c("br"),
              _c("fieldset", [
                _c("legend", [_vm._v("Dati del Contraente")]),
                _c("table", { staticClass: "table" }, [
                  _c(
                    "tbody",
                    [
                      _c("tr", [
                        _c("td", { staticClass: "domanda" }, [
                          _vm._v("Tipo persona: "),
                        ]),
                        _c("td", { staticClass: "risposta" }, [
                          _vm._v(_vm._s(_vm.cliente.tipo_persona)),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "domanda" }, [
                          _vm._v("Cognome / Ragione sociale: "),
                        ]),
                        _c("td", { staticClass: "risposta" }, [
                          _vm._v(_vm._s(_vm.cliente.cognome)),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "domanda" }, [
                          _vm._v("Nome: "),
                        ]),
                        _c("td", { staticClass: "risposta" }, [
                          _vm._v(_vm._s(_vm.cliente.nome)),
                        ]),
                      ]),
                      _vm.cliente.tipo_persona === "persona_fisica"
                        ? _c("tr", [
                            _c("td", { staticClass: "domanda" }, [
                              _vm._v("Codice Fiscale: "),
                            ]),
                            _c("td", { staticClass: "risposta" }, [
                              _vm._v(_vm._s(_vm.cliente.codice_fiscale)),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.cliente.tipo_persona !== "persona_fisica"
                        ? _c("tr", [
                            _c("td", { staticClass: "domanda" }, [
                              _vm._v("Partita IVA: "),
                            ]),
                            _c("td", { staticClass: "risposta" }, [
                              _vm._v(_vm._s(_vm.cliente.partita_iva)),
                            ]),
                          ])
                        : _vm._e(),
                      _c("tr", [
                        _c("td", { staticClass: "domanda" }, [
                          _vm._v("Professione: "),
                        ]),
                        _c("td", { staticClass: "risposta" }, [
                          _vm._v(_vm._s(_vm.cliente.professione)),
                        ]),
                      ]),
                      _vm.cliente.tipo_persona === "persona_fisica"
                        ? [
                            _c("tr", [
                              _c("td", { staticClass: "domanda" }, [
                                _vm._v("Sesso: "),
                              ]),
                              _c("td", { staticClass: "risposta" }, [
                                _vm._v(_vm._s(_vm.cliente.sesso)),
                              ]),
                            ]),
                            _c("tr", [
                              _c("td", { staticClass: "domanda" }, [
                                _vm._v("Data di nascita: "),
                              ]),
                              _c("td", { staticClass: "risposta" }, [
                                _vm._v(_vm._s(_vm.cliente.data_nascita)),
                              ]),
                            ]),
                            _c("tr", [
                              _c("td", { staticClass: "domanda" }, [
                                _vm._v("Comune di nascita: "),
                              ]),
                              _c("td", { staticClass: "risposta" }, [
                                _vm._v(_vm._s(_vm.cliente.comune_nascita)),
                              ]),
                            ]),
                            _c("tr", [
                              _c("td", { staticClass: "domanda" }, [
                                _vm._v("Provincia di nascita: "),
                              ]),
                              _c("td", { staticClass: "risposta" }, [
                                _vm._v(_vm._s(_vm.cliente.provincia_nascita)),
                              ]),
                            ]),
                          ]
                        : _vm._e(),
                      _vm.cliente.tipo_persona !== "persona_fisica"
                        ? _c("tr", [
                            _c("td", { staticClass: "domanda" }, [
                              _vm._v("Codice univoco destinatario: "),
                            ]),
                            _c("td", { staticClass: "risposta" }, [
                              _vm._v(
                                _vm._s(_vm.cliente.codice_univoco_destinatario)
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _c("tr", [
                        _c("td", { staticClass: "domanda" }, [
                          _vm._v("Indirizzo: "),
                        ]),
                        _c("td", { staticClass: "risposta" }, [
                          _vm._v(_vm._s(_vm.cliente.indirizzo_residenza)),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "domanda" }, [
                          _vm._v("Comune: "),
                        ]),
                        _c("td", { staticClass: "risposta" }, [
                          _vm._v(_vm._s(_vm.cliente.comune_residenza)),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "domanda" }, [_vm._v("Cap: ")]),
                        _c("td", { staticClass: "risposta" }, [
                          _vm._v(_vm._s(_vm.cliente.cap_residenza)),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "domanda" }, [
                          _vm._v("Provincia: "),
                        ]),
                        _c("td", { staticClass: "risposta" }, [
                          _vm._v(_vm._s(_vm.cliente.provincia_residenza)),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "domanda" }, [
                          _vm._v("Telefono: "),
                        ]),
                        _c("td", { staticClass: "risposta" }, [
                          _vm._v(_vm._s(_vm.cliente.telefono)),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "domanda" }, [
                          _vm._v("Cellulare: "),
                        ]),
                        _c("td", { staticClass: "risposta" }, [
                          _vm._v(_vm._s(_vm.cliente.cellulare)),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "domanda" }, [
                          _vm._v("Email: "),
                        ]),
                        _c("td", { staticClass: "risposta" }, [
                          _vm._v(_vm._s(_vm.cliente.email)),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "domanda" }, [_vm._v("Pec: ")]),
                        _c("td", { staticClass: "risposta" }, [
                          _vm._v(_vm._s(_vm.cliente.pec)),
                        ]),
                      ]),
                    ],
                    2
                  ),
                ]),
                _c("br"),
              ]),
              _c("br"),
              _c("fieldset", [
                _c("legend", [_vm._v("Dati della Fattura")]),
                _c("table", { staticClass: "table" }, [
                  _c("tbody", [
                    _c("tr", [
                      _c("td", { staticClass: "domanda" }, [
                        _vm._v("Intermediario Capogruppo: "),
                      ]),
                      _c("td", { staticClass: "risposta" }, [
                        _vm._v(
                          _vm._s(_vm.fattura.nome_intermediario_capogruppo)
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "domanda" }, [
                        _vm._v("Data di creazione: "),
                      ]),
                      _c("td", { staticClass: "risposta" }, [
                        _vm._v(
                          _vm._s(_vm.AggiustaFormatoDate(_vm.fattura.create_at))
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "domanda" }, [
                        _vm._v(
                          "Giorni rimanenti prima dell'invio all'Agenzia delle entrate: "
                        ),
                      ]),
                      _c("td", { staticClass: "risposta" }, [
                        _vm._v(_vm._s(_vm.fattura.giorni_rimasti) + " giorni"),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "domanda" }, [
                        _vm._v("Premio contratto in Fattura: "),
                      ]),
                      _c("td", { staticClass: "risposta" }, [
                        _vm._v(_vm._s(_vm.fattura.premio) + " Euro"),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "domanda" }, [
                        _vm._v("Consulenza totale in Fattura: "),
                      ]),
                      _c("td", { staticClass: "risposta" }, [
                        _vm._v(_vm._s(_vm.fattura.consulenza_totale) + " Euro"),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "domanda" }, [
                        _vm._v("Premio complessivo di Fattura: "),
                      ]),
                      _c("td", { staticClass: "risposta" }, [
                        _vm._v(
                          _vm._s(
                            _vm.fattura.consulenza_totale + _vm.fattura.premio
                          ) + " Euro"
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _c("br"),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "col-12 col-md-5 q-pr-md",
              attrs: { align: "left" },
            },
            [
              _c("br"),
              _c("fieldset", [
                _c("legend", [_vm._v("Dati della pratica")]),
                _c("table", { staticClass: "table" }, [
                  _c("tbody", [
                    _c("tr", [
                      _c("td", { staticClass: "domanda" }, [
                        _vm._v("Numero interno pratica: "),
                      ]),
                      _c("td", { staticClass: "risposta" }, [
                        _vm._v(_vm._s(_vm.pratica.numero)),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "domanda" }, [
                        _vm._v("Numero pratica: "),
                      ]),
                      _c("td", { staticClass: "risposta" }, [
                        _vm._v(_vm._s(_vm.pratica.numero_pratica)),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "domanda" }, [
                        _vm._v("Unità Operatica: "),
                      ]),
                      _c("td", { staticClass: "risposta" }, [
                        _vm._v(_vm._s(_vm.pratica.unita_operativa)),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "domanda" }, [
                        _vm._v("Persona Operatica: "),
                      ]),
                      _c("td", { staticClass: "risposta" }, [
                        _vm._v(_vm._s(_vm.pratica.operatore)),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "domanda" }, [
                        _vm._v("Data di creazione: "),
                      ]),
                      _c("td", { staticClass: "risposta" }, [
                        _vm._v(
                          _vm._s(_vm.AggiustaFormatoDate(_vm.pratica.create_at))
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "domanda" }, [
                        _vm._v("Data di ultimo aggiornamento: "),
                      ]),
                      _c("td", { staticClass: "risposta" }, [
                        _vm._v(
                          _vm._s(_vm.AggiustaFormatoDate(_vm.pratica.update_at))
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "domanda" }, [
                        _vm._v("Data di definizione: "),
                      ]),
                      _c("td", { staticClass: "risposta" }, [
                        _vm._v(
                          _vm._s(
                            _vm.AggiustaFormatoDate(_vm.pratica.defined_at)
                          )
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "domanda" }, [
                        _vm._v("Stato attuale della pratica: "),
                      ]),
                      _c("td", { staticClass: "risposta" }, [
                        _vm._v(_vm._s(_vm.pratica.stato)),
                      ]),
                    ]),
                  ]),
                ]),
                _c("br"),
              ]),
              _c("br"),
              _c("fieldset", [
                _c("legend", [_vm._v("Dati del contratto")]),
                _c("table", { staticClass: "table" }, [
                  _c("tbody", [
                    _c("tr", [
                      _c("td", { staticClass: "domanda" }, [
                        _vm._v("Tipo di contratto: "),
                      ]),
                      _c("td", { staticClass: "risposta" }, [
                        _vm._v(_vm._s(_vm.contratto.prodotto_id)),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "domanda" }, [
                        _vm._v("Impresa di Assicurazione: "),
                      ]),
                      _c("td", { staticClass: "risposta" }, [
                        _vm._v(_vm._s(_vm.contratto.compagnia)),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "domanda" }, [
                        _vm._v("Numero Polizza Compagnia: "),
                      ]),
                      _c("td", { staticClass: "risposta" }, [
                        _vm._v(_vm._s(_vm.contratto.numero_polizza_compagnia)),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "domanda" }, [
                        _vm._v("Fornitore: "),
                      ]),
                      _c("td", { staticClass: "risposta" }, [
                        _vm._v(_vm._s(_vm.contratto.fornitore)),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "domanda" }, [
                        _vm._v("Decorrenza: "),
                      ]),
                      _c("td", { staticClass: "risposta" }, [
                        _vm._v(
                          _vm._s(
                            _vm.AggiustaFormatoDate(_vm.contratto.decorrenza)
                          )
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "domanda" }, [
                        _vm._v("Scadenza contrattuale: "),
                      ]),
                      _c("td", { staticClass: "risposta" }, [
                        _vm._v(
                          _vm._s(
                            _vm.AggiustaFormatoDate(_vm.contratto.scadenza)
                          )
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "domanda" }, [
                        _vm._v("Scadenza di rata: "),
                      ]),
                      _c("td", { staticClass: "risposta" }, [
                        _vm._v(
                          _vm._s(
                            _vm.AggiustaFormatoDate(_vm.quietanza.scadenza)
                          )
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "domanda" }, [
                        _vm._v("Premio contratto: "),
                      ]),
                      _c("td", { staticClass: "risposta" }, [
                        _vm._v(
                          _vm._s(_vm.quietanza.premio.toFixed(2)) + " Euro"
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _c("br"),
              ]),
              _c("br"),
              _c("fieldset", [
                _c("legend", [_vm._v("Dati economici")]),
                _c("table", { staticClass: "table" }, [
                  _c(
                    "tbody",
                    _vm._l(
                      _vm.get_dati_economici,
                      function (dato_economico, index) {
                        return _c(
                          "tr",
                          _vm._b({ key: index }, "tr", dato_economico, false),
                          [
                            _c("td", { staticClass: "domanda" }, [
                              _vm._v(_vm._s(dato_economico.tipo) + ": "),
                            ]),
                            _c("td", { staticClass: "risposta" }, [
                              _vm._v(_vm._s(dato_economico.importo) + " Euro"),
                            ]),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]),
                _c("br"),
              ]),
            ]
          ),
        ]),
        _c("div", { attrs: { align: "center" } }, [
          _c("hr"),
          _c("div", { staticClass: "row justify-center" }, [
            _c(
              "div",
              { staticClass: "col-md-4", attrs: { align: "center" } },
              [
                _c("QQButton", {
                  attrs: {
                    label: "INDIETRO",
                    color: "blue-grey",
                    icon: "undo",
                    size: "md",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.onIndietroClicked()
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("br"),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { align: "center" } }, [
      _c("span", { staticClass: "text-h4" }, [_vm._v("Menu gestione Fattura")]),
      _c("br"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }